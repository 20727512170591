<template>
  <div style="height: calc(100vh - 225px); overflow:auto;">
    <v-card
      hover
      outlined
      class="mb-5 mx-5 mt-5"
      v-for="notificacao in notificacoes"
      :key="notificacao.id"
      @click="toGoCalendario(notificacao)"
    >
      <div v-if="notificacao.calendario">
        <v-card-title
          class="grey lighten-5 pt-1 pb-0 text-subtitle-1 font-weight-black"
          >{{ notificacao.calendario.cliente_nome_fantasia }} | Início:
          {{ notificacao.calendario.inicio | dateFormat("dd/MM/yyyy") }}
          - Fim:
          {{ notificacao.calendario.fim | dateFormat("dd/MM/yyyy") }}
          <v-spacer></v-spacer>
          {{ notificacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-card
          tile
          flat
          class="pt-5 pb-5 grey lighten-5 d-flex text-center align-center text-justify-center font-weight-black"
        >
          <v-avatar class="mx-3 " size="50" v-if="notificacao.usuario_avatar">
            <v-img :src="notificacao.usuario_avatar" contain></v-img>
          </v-avatar>
          <v-icon v-else class="mx-3" size="50">mdi-account-circle</v-icon>
          {{ notificacao.usuario_name }}

          <v-btn rounded class="ml-2" x-small dark
            >{{ notificacao.acao }} {{ notificacao.oque }}</v-btn
          >
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="corStatusCalendario(notificacao.acao_valor_antigo)"
            >{{ +notificacao.acao_valor_antigo | statusCalendario }} ->
          </v-btn>
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="corStatusCalendario(notificacao.acao_valor_novo)"
            >{{ +notificacao.acao_valor_novo | statusCalendario }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.stop="lerNotificacao(notificacao)" icon
            ><v-icon color="blue"
              >mdi-chevron-down-circle-outline</v-icon
            ></v-btn
          >
        </v-card>
      </div>
      <div v-else-if="notificacao.atividade">
        <v-card-title
          class=" grey lighten-5 pt-1 pb-0 text-subtitle-1 font-weight-black"
          >{{ notificacao.atividade.cliente_nome_fantasia }} |
          {{ notificacao.atividade.titulo }}
          <v-spacer></v-spacer>
          {{ notificacao.created_at | dateFormat("dd/MM/yyyy  HH:mm:ss") }}
        </v-card-title>
        <v-divider class=""></v-divider>
        <v-card
          tile
          flat
          class="pt-5 pb-5 grey lighten-5 d-flex text-center align-center text-justify-center font-weight-black"
        >
          <v-avatar class="mx-3 " size="50" v-if="notificacao.usuario_avatar">
            <v-img :src="notificacao.usuario_avatar" contain></v-img>
          </v-avatar>
          <v-icon v-else class="mx-3" size="50">mdi-account-circle</v-icon>
          {{ notificacao.usuario_name }}
          <v-btn rounded class="ml-2" x-small dark
            >{{ notificacao.acao }} {{ notificacao.oque }}</v-btn
          >
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="
              notificacao.acao_valor_antigo == 0
                ? 'grey'
                : notificacao.acao_valor_antigo == 1
                ? 'blue-grey'
                : notificacao.acao_valor_antigo == 2
                ? 'yellow darken-4'
                : notificacao.acao_valor_antigo == 3
                ? 'green'
                : notificacao.acao_valor_antigo == 4
                ? 'purple'
                : notificacao.acao_valor_antigo == 5
                ? 'green darken-4'
                : notificacao.acao_valor_antigo == 6
                ? 'deep-purple accent-4'
                : notificacao.acao_valor_antigo == -1
                ? 'red'
                : 'light-green'
            "
            >{{ +notificacao.acao_valor_antigo | statusAtividade }} ->
          </v-btn>
          <v-btn
            class="ml-2"
            x-small
            dark
            :color="
              notificacao.acao_valor_novo == 0
                ? 'grey'
                : notificacao.acao_valor_novo == 1
                ? 'blue-grey'
                : notificacao.acao_valor_novo == 2
                ? 'yellow darken-4'
                : notificacao.acao_valor_novo == 3
                ? 'green'
                : notificacao.acao_valor_novo == 4
                ? 'purple'
                : notificacao.acao_valor_novo == 5
                ? 'green darken-4'
                : notificacao.acao_valor_novo == 6
                ? 'deep-purple accent-4'
                : notificacao.acao_valor_novo == -1
                ? 'red'
                : 'light-green'
            "
            >{{ +notificacao.acao_valor_novo | statusAtividade }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.stop="lerNotificacao(notificacao)" icon
            ><v-icon color="blue"
              >mdi-chevron-down-circle-outline</v-icon
            ></v-btn
          >
        </v-card>
      </div>
    </v-card>
    <AtividadesGestor
      v-if="dialogEditAtividadeGestor"
      :dialogEditAtividadeGestor.sync="dialogEditAtividadeGestor"
      :atividadeSelected="atividadeSelected"
      @fetch-notificacoes="changeType"
    />
    <AtividadesColaborador
      v-if="dialogAtividadeColaborador"
      :dialogAtividadeColaborador.sync="dialogAtividadeColaborador"
      :item="atividadeSelected"
      @fetch-notificacoes="changeType"
    />
    <AtividadesClientes
      v-if="dialogAtividadeCliente"
      :dialogAtividadeCliente.sync="dialogAtividadeCliente"
      :item="atividadeSelected"
      @fetch-notificacoes="changeType"
    />
    <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
      <div slot="no-results" class="my-6">
        Você ainda não tem nenhuma notificacão!
      </div>
      <div slot="spinner" class="my-6">
        <v-icon>mdi-loading mdi-spin</v-icon> Carregando...
      </div>
      <div slot="no-more" class="my-6">
        Todas as suas notificacões foram carregadas.
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { postNotificao } from "@/api/geral/notificacoes.js";
import { mapActions } from "vuex";
import {
  fetchNotificacoes,
  fetchNotificacoesColaborador,
  fetchNotificacoesCliente,
} from "@/api/geral/notificacoes.js";
export default {
  name: "NovasNotificacoes",
  props: {
    perfil_id: {
      type: [String, Number],
      default: null,
    },
    user_id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      notificacoes: [],
      loading: false,
      page: 1,
      infiniteId: +new Date(),
      dialogEditAtividadeGestor: false,
      dialogAtividadeColaborador: false,
      dialogAtividadeCliente: false,
      atividadeSelected: null,
    };
  },
  components: {
    InfiniteLoading,
    AtividadesGestor: () =>
      import("../components_atividades/AtividadesGestor.vue"),
    AtividadesColaborador: () =>
      import("../components_atividades/AtividadesColaborador.vue"),
    AtividadesClientes: () =>
      import("../components_atividades/AtividadesClientes.vue"),
  },
  methods: {
    ...mapActions("Notificacoes", {
      STORE_count: "count",
    }),
    corStatusCalendario(item) {
      let result;
      if (item) {
        if (item == -1) {
          result = "#F44336";
        }
        if (item == 1) {
          result = "#263238";
        }
        if (item == 2) {
          result = "#E65100";
        }
        if (item == 3) {
          result = "#33691E";
        }
        if (item == 4) {
          result = "#1B5E20";
        }
      }
      return result;
    },
    toGoCalendario(notificacao) {
      if (this.perfil_id == 4) {
        if (notificacao.fonte == "atividades_clientes") {
          this.atividadeSelected = notificacao.fonte_id;
          this.dialogAtividadeCliente = true;
        } else if (notificacao.fonte == "clientes_calendarios") {
          this.$router.push({
            path: `/cliente/midias-sociais/calendario-midias-sociais/${notificacao.fonte_id}`,
          });
        }
      } else if (this.perfil_id == 3) {
        if (notificacao.fonte == "atividades_clientes") {
          this.atividadeSelected = notificacao.fonte_id;
          this.dialogAtividadeColaborador = true;
        } else if (notificacao.fonte == "clientes_calendarios") {
          this.$router.push({
            path: `/colaborador/midias-sociais/calendario-midias-sociais/${notificacao.fonte_id}`,
          });
        }
      } else if (this.perfil_id == 2 || this.perfil_id == 1) {
        if (notificacao.fonte == "atividades_clientes") {
          this.atividadeSelected = notificacao.fonte_id;
          this.dialogEditAtividadeGestor = true;
        } else if (notificacao.fonte == "clientes_calendarios") {
          this.$router.push({
            path: `/gestor/formulario-trabalho/midias-sociais/calendario-midias-sociais/${notificacao.fonte_id}`,
          });
        }
      }
    },
    lerNotificacao(notificacao) {
      let body = {};

      body.user_id = this.user_id;
      body.notificacao_id = notificacao.id;

      postNotificao(body).then((response) => {
        if (response.status === 201) {
          this.STORE_count();
          this.changeType();
        }
      });
    },
    infiniteHandler($state) {
      if (this.perfil_id == 1 || this.perfil_id == 2) {
        this.loading = true;
        fetchNotificacoes(`?user_id=${this.user_id}&page=${this.page}`).then(
          ({ data }) => {
            if (data.length) {
              this.page += 1;
              this.notificacoes.push(...data);
              $state.loaded();
              this.loading = false;
            } else {
              $state.complete();
              this.loading = false;
            }
          }
        );
      } else if (this.perfil_id == 3) {
        this.loading = true;
        fetchNotificacoesColaborador(
          `?user_id=${this.user_id}&page=${this.page}`
        ).then(({ data }) => {
          if (data.length) {
            this.page += 1;
            this.notificacoes.push(...data);
            $state.loaded();
            this.loading = false;
          } else {
            $state.complete();
            this.loading = false;
          }
        });
      } else if (this.perfil_id == 4) {
        this.loading = true;
        fetchNotificacoesCliente(
          `?user_id=${this.user_id}&page=${this.page}`
        ).then(({ data }) => {
          if (data.length) {
            this.page += 1;
            this.notificacoes.push(...data);
            $state.loaded();
            this.loading = false;
          } else {
            $state.complete();
            this.loading = false;
          }
        });
      }
    },
    changeType() {
      this.page = 1;
      this.notificacoes = [];
      this.infiniteId += 1;
    },
  },
};
</script>

<style></style>
